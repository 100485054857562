import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox",
      "style": {
        "position": "relative"
      }
    }}>{`Checkbox`}</h1>
    <p>{`Checkboxes are used when there is a list of options and the user may select multiple options, including all or none. Each checkbox is independent of all other checkboxes in the list, and checking one box doesn't uncheck the others.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`By default, Checkboxes are unchecked.`}</li>
      <li parentName="ul">{`The selection should be able by clicking on the box directly or by clicking on its label.`}</li>
      <li parentName="ul">{`Labels appear to the right of checkboxes.`}</li>
      <li parentName="ul">{`For lists with more than 4 options, consider using `}<a parentName="li" {...{
          "href": "/components/multi-select"
        }}>{`Multi-Select`}</a>{`.`}</li>
    </ul>
    <Demo src='pages/components/checkbox/Checkbox' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      